.navLeft {
  background: #323138;
  width: var(--navigation-width);
  display: flex;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  flex: 0 0 auto;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  transition: 200ms width;
  position: relative;
  z-index: 6;
}
.navLeftNarrow,
.navLeftBody {
  padding-bottom: 175px;
  padding-top: 66px;
  position: relative;
  overflow-y: auto;
  max-height: 100vh;
  min-height: calc(100% - var(--navigation-width));
}
.linkIcon {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
}
.linkIcon img {
  display: block;
  width: 20px;
}
.navLeftFooter {
  bottom: 0;
}
.navLeftFooter,
.navLeftHeader {
  background: #323138;
  transition: 200ms width;
  position: fixed;
  width: var(--navigation-width);
  z-index: 2;
}
.navLeftHeader {
  justify-content: space-between;
}
.defaulHeaderPositioning {
  top: 0;
}
.navLeftLink a {
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.navLeftLink a strong {
  font-size: 1.1rem;
  width: -webkit-fill-available;
  width: -moz-available;
}
.navLeftLink a:hover {
  background: rgba(145, 131, 210, 0.51);
}
.navLeftLink .active {
  background: #765ee3;
}

.navLeftLink > a:focus {
  background: rgba(198, 195, 209, 0.85);
  outline: 0;
}
.navLeftLink .active .linkIcon img,
.navLeftLink a:hover .linkIcon img,
.navLeftLink a:focus .linkIcon img {
  filter: brightness(200%) contrast(1.5);
}
.navLeftLinkHasList {
  position: relative;
}
.navLeftLinkHasList .toggleBtn {
  position: absolute;
  top: 5px;
  right: 10px;
}
.menuToggleBtn,
.toggleBtn {
  width: 26px;
  min-width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  border: 0;
  border-radius: 50%;
  background: transparent;
}
.menuToggleBtn:hover {
  background: #222;
}
.menuToggleBtn:focus {
  background: #000;
  outline: 0;
}
.toggleBtn img {
  display: block;
  width: auto;
}
.toggleBtnClosed {
  transform: rotate(180deg);
}
.toggleBtn:focus {
  outline: 0;
}
.navLeftList .navLeftSubLink a {
  color: #adabab;
}
.navLeftList .navLeftSubLink a strong {
  font-weight: 400;
  font-size: 0.95rem;
}
.appsMenu {
  position: absolute;
  width: 340px;
  z-index: 5;
  background: #fefefe;
  overflow: auto;
  position: fixed;
  height: 100vh;
  top: 0;
  left: -100%;
  box-shadow: 2px 5px 30px 4px rgba(0, 0, 0, 0.1), 1px 0px 6px 2px rgba(0, 0, 0, 0.1),
    -2px 4px 8px 0px rgba(0, 0, 0, 0.05);
  max-height: 100vh;
  overflow-y: auto;
  background: #323138;
  padding: 0 0 30px;
  transition: 200ms left;
}
.appsMenuOpen {
  left: 210px;
  visibility: visible;
  height: fit-content;
  bottom: 0;
  top: inherit;
}
.appList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px 0;
  border-radius: 4px;
  background: transparent;
  margin-top: 8px;
}
.appListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  text-decoration: none;
}
.appListItem img {
  width: 100%;
}
.appListItem:hover {
  text-decoration: none;
}
.appListItem:hover .appListItemTitle {
  color: #01b0b9;
}
.appListItemIcon {
  width: 40px;
  height: 40px;
}
.btnCircularMd .appListItemIcon img {
  display: block;
  width: 100%;
}
.appListItemTitle {
  color: #808080;
  font-weight: 600;
  margin-top: 10px;
  font-size: 0.9rem;
}
.createMenu {
  position: absolute;
  width: 210px;
  z-index: 2;
  background: #fefefe;
  overflow: auto;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  transition: 200ms left;
  box-shadow: 2px 5px 30px 4px rgba(0, 0, 0, 0.1), 1px 0px 6px 2px rgba(0, 0, 0, 0.1),
    -2px 4px 8px 0px rgba(0, 0, 0, 0.05);
  padding: 20px 0 20px 7px;
  display: flex;
}
.createMenuOpen {
  left: 0;
  visibility: visible;
}

.linkToKivy {
  width: 100%;
  cursor: pointer;
}
.linkToKivy img {
  width: 30px;
  margin: 0 auto;
  cursor: pointer;
}
.linkToKivy a {
  text-align: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
.linkToKivy a strong {
  color: grey;
  font-size: 0.7rem;
}
.btnCircularMd {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
}
.btnCircular {
  border-radius: 50px;
  width: 26px;
  min-width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  border: 0;
  background: transparent;
}
.btnCircularMd img,
.btnCircular img {
  display: block;
  width: auto;
}
.btnCircular:hover {
  background: rgba(0, 0, 0, 0.05);
}
.btnCircular:focus {
  background: rgba(0, 0, 0, 0.1);
  outline: none;
}
.navLeftLabel {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 4px;
  background: transparent;
  margin-right: 10px;
}
.bgBlue {
  background: #01c9d5;
  color: #fff;
}
.btnCircularMd.bgBlue:hover {
  background: rgba(69, 210, 218, 0.6);
}
.bgPurple {
  background: #5b3fdd;
}
.btnCircularMd.bgPurple:hover {
  background: rgba(91, 63, 221, 0.6);
}
.bgOrange {
  background: orange;
  color: #fff;
}
.bgRed {
  background: #ec6c74;
  color: #fff;
}
.bgCoral {
  background: #ec6c74;
  color: #fff;
}
.bgYellowOrange {
  background: #d58d01;
  color: #fff;
}
.bgRedOrange {
  background: #f7743e;
  color: #fff;
}
.bgYellow {
  background: #d5cd01;
  color: #fff;
}
.bgPink {
  background: #d50180;
  color: #fff;
}
.bgGrey {
  background: grey;
  color: #fff;
}
.bgRed {
  background: #ff6e75;
  color: #fff;
}
.bgDarkred {
  background: #d50e01;
  color: #fff;
}
.bgBlueviolet {
  background: #8a2be2;
  color: #fff;
}
.bgGreen {
  background: #01d5a2;
  color: #fff;
}
.bgDarkblue {
  background: #00008b;
  color: #fff;
}
.bgDarkcyan {
  background: #008b8b;
  color: #fff;
}
.bgMidblue {
  background: #007bff;
  color: #fff;
}
.bgCadetblue {
  background: #5f9ea0;
  color: #fff;
}
.bgBurlywood {
  background: #deb887;
  color: #fff;
}

.orderCounter {
  position: sticky;
  right: 0;
  border-radius: 10px;
  background: #01c9d5;
  color: #333;
  min-width: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0 6px;
}
.memberGroup {
  display: flex;
  align-items: center;
}
.memberAvatar {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
}
.memberAvatar img {
  border: 2px solid #323138;
  border-radius: 50%;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: #323138;
}
.btnAddUser {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  border: 2px solid #343f40;
  width: 32px;
  height: 32px;
  min-width: 32px;
  left: -10px;
  border-radius: 50%;
  background: #765ee3;
}
.btnAddUser:hover {
  background: #5a3fb5;
}
.btnAddUser:focus {
  background: #5238a7;
  outline: 0;
}
.btnAddUser img {
  display: block;
  width: auto;
}

.closed .menuToggleBtn img {
  transform: rotate(180deg);
}
.closed {
  width: 70px;
}
.closed .navLeftHeader,
.closed .navLeftFooter {
  justify-content: center;
  width: 70px;
}
.closed .navLeftHeader .logo,
.closed .contentWrapper {
  display: none;
}
.contentWrapper {
  height: calc(100vh - 220px);
  overflow-y: auto;
  scrollbar-color: #fff #323138;
}
