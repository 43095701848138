.courierLogo {
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.courierLogoWrapper {
  display: flex;
  align-items: center;
  padding: 6px 0;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  height: 100%;
}
