.spinnerFile {
  height: 70px;
  margin-top: 10px;
}

.modal {
  width: 500px !important;
  padding: 20px 15px;
}

.dropFileArea {
  cursor: pointer;
  min-height: 250px;
  height: 320px;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fileIcon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.progress {
  display: flex;
  margin-top: 6px;
  height: 6px;
  background-color: #c4c4c4;
  border-radius: 8px;
}

.progressBar {
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #856afe;
  transition: width 0.6s ease;
}

.filesWrapper {
  max-height: 310px;
  overflow: auto;
}
.file {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.file:first-child {
  border-bottom: none;
  margin-top: 20px;
}

.file:last-child {
  border-bottom: none;
  margin-bottom: 20px;
}

.name {
  word-break: break-all;
}
