.moreOptions {
  background: rgba(255, 255, 255, 0.15);
}

.tooltip {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px 2px, rgba(0, 0, 0, 0.05) 0px 8px 7px 9px;
  border-radius: 8px !important;
  pointer-events: auto !important;
  opacity: 1 !important;
  padding: 0 !important;
}

.tooltip:hover {
  visibility: visible !important;
}

.tooltip:global(.__react_component_tooltip.place-left)::before {
  top: 82% !important;
}
.tooltip:global(.__react_component_tooltip.place-left)::after {
  top: 82% !important;
}

.tooltipContainer {
  width: 290px;
}
