.popover {
  font-family: "Sofia Sans", sans-serif;
  padding: 10px 15px;
  border-radius: 8px;
  z-index: 30;
  gap: 6px;
  box-shadow: 0px 6px 16px -6px rgba(17, 12, 34, 0.1);
}

.light {
  background: var(--neutralBlack100);
  color: var(--neutralWhite100);
}

.dark {
  background: var(--neutralWhite100);
  color: var(--neutralBlack100);
}
