.container {
  height: 70px;
  background: #0000;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  z-index: 2;
  padding-right: 15px;
  right: 0;
  top: 54px;
}
.primaryBtn {
  padding: 0 12px;
  height: 40px;
  margin-right: 29px;
}
.toggleButtonMargin {
  margin-right: 12px;
}
.toggleButton {
  background-color: #fff;
  box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.03), 0px 3px 5px 2px rgba(0, 0, 0, 0.06);
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
}

.toggleButton:hover {
  background-color: #ddd;
}

.toggleBtnSelected {
  background: var(--deepPurple100);
}

.toggleBtnSelected:hover {
  background-color: var(--deepPurple200);
}
