.avatarBox {
  position: relative;
  display: flex;
  align-items: center;
  width: 125px;
  max-width: 125px;
  overflow: hidden;
}

.invoiceSpacing {
  letter-spacing: -0.03rem;
}

.highOpacity {
  opacity: 0.75;
}

.confirmationBtn {
  max-height: 20px !important;
}
