.wrapper:first-child {
  padding-top: 5px;
}

.wrapper {
  border-bottom: 1px solid #ffffff1a;
  padding: 20px 20px 13px 20px;
  border-top: 1px solid #ffffff1a;
}

.label {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--neutralWhite100);
  margin-bottom: 20px;
}

.filtersBody::-webkit-scrollbar {
  width: 0;
  display: none;
}

.filtersBody {
  height: 100%;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
  scrollbar-color: transparent transparent;
  width: 100%;
}
