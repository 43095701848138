.selectedPlaceholder-light::placeholder {
  color: var(--neutralBlack88);
}
.selectedPlaceholder-dark::placeholder {
  color: var(--neutralWhite100);
}

.selected {
  background-color: var(--deepPurple75);
}
