.dropMenu {
  outline: none;
  min-width: 140px;
  max-width: 460px;
  max-height: 200px;
  overflow: auto;
  padding: 10px 0px;
  border-radius: 0px 4px 4px 4px;
  border: 1px solid var(--neutralBlack7);
  background: #fff;
  box-shadow: 0px 32px 32px -12px var(--neutralBlack24);
  z-index: 3;
}

.dropDownOption {
  align-items: center;
  background: var(--neutral-white-100);
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 5px 15px;
  width: 100%;
}

.dropDownOption:focus {
  background: var(--deepPurple50);
  box-shadow: 0px 0px 0px 1px var(--deepPurple200) inset;
  outline: none;
}

.dropDownOption:hover {
  background: var(--neutralBlack7);
  box-shadow: none;
}

.dropDownOption:active {
  background: var(--deepPurple75);
  box-shadow: none;
}

.divider {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  padding: 5px 0;
}

.dividerLine {
  height: 0px;
  flex: 1 0 0;
  border-top: 1px solid var(--neutral-black-16);
}

.section {
  width: 100%;
}

.sectionDisclosure {
  display: flex;
  justify-content: space-between;
}

.header {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  padding: 5px 15px;
  width: 100%;
}

.headerTypography {
  line-height: 16px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
