.textarea {
  display: flex;
  width: 100%;
  height: 90px;
  line-height: 22px;
  border-radius: 5px;
  min-height: 200px;
  border: 1px solid transparent;
  background: #f9f9f9;
  padding: 10px;
  overflow: auto;
  resize: vertical;
}

.responseModalTitle {
  background-color: var(--success500);
  border-radius: 8px 8px 0 0;
}

.successTitle {
  background-color: var(--success500);
  border-radius: 8px 8px 0 0;
}
