
.optionList {
  position: absolute !important;
  z-index: 1;
  top: calc(100% + 12px);
  right: -40px;
  min-width: max-content;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
}

.optionList .search {
  width: 100%;
  min-width: 100%;
}
.optionRow a {
  color: #222;
}
.optionRow img {
  width: 18px;
  min-width: 18px;
}
.optionRow:hover {
  background: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
/* POPUP STYLES */

.tooltip {
  pointer-events: auto !important;
  opacity: 1 !important;
  padding: 0 !important;
}

.tooltip:hover {
  visibility: visible !important;
}

.tooltip:global(.__react_component_tooltip.place-left)::before {
  display: none;
}
.tooltip:global(.__react_component_tooltip.place-left)::after {
  display: none;
}

.tooltipContainer {
  width: 160px;
}

.moreDataCounterOverride {
  width: max-content;
}

.labelText {
  font-size: 12px;
  line-height: 12px;
  color: #808080;
  letter-spacing: -0.48px;
}

.smallBtnHeight {
  max-height: 26px;
}
