.container {
  margin: 15px 0 4px;
}

.slider {
  padding: 0 8px;
}

.label {
  font-size: 12px;
  font-weight: 700;
  color: #565656;
  margin-bottom: 18px;
}

.labelValues {
  font-size: 0.75rem;
  padding-top: 15px;
  font-weight: 700;
  color: #333;
  margin: 0px 0 12px;
  justify-content: space-between;
  display: flex;
}

.labelValues span {
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 70px;
  height: 25px;
  align-items: center;
  justify-content: center;
}

.fromValue {
  left: 0px;
  display: flex;
}

.labelValues .toValue {
  margin-left: auto;
  display: flex;
}

.fieldset_label {
  font-size: 1.4rem;
  font-weight: 600;
  color: #666;
  width: 100%;
  margin-top: 8px;
  font-family: "Cabin", sans-serif;
  margin-bottom: 12px;
}
