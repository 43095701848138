.drawer {
  min-width: 700px;
  max-width: 700px;
}
.signature {
  font-weight: 700;
  width: -webkit-fill-available;
  width: -moz-available;
}
.selectBox {
  min-width: 50%;
  position: relative;
}
.client {
  font-size: 0.9rem;
  color: #808080;
}
.textColorPurple {
  color: #765ee3;
}
.createdBy {
  font-size: 0.9rem;
  margin-right: 6px;
}
.createdDate {
  font-size: 0.75rem;
  color: #808080;
}
.btnReady {
  background-color: #01c9d5;
  color: #fff;
  border-color: #01c9d5;
}
.btnReady img {
  filter: contrast(0%) brightness(200%);
}
.createdLabel {
  font-size: 12px;
  color: #808080;
}
.errorLabel {
  margin-bottom: 8px;
  font-size: 13px;
  color: #808080;
}
.deliveryTime {
  font-weight: 300;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100px;
  min-width: 100px;
  text-align: right;
}
.deliveryTime span {
  color: #808080;
}
.link {
  padding: 1px 5px 1px 0;
  background: url(~assets/images/121.svg) no-repeat top right;
}
.orderSignature {
  color: #212529;
  font-size: 12px;
}
.orderSignature:hover {
  color: #01c9d5;
}
.smsBtn {
  color: #01c9d5;
}
.smsBtnGrey {
  color: #808080;
}
.smsNotification {
  margin-right: 2px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}
.smsNotification img {
  filter: contrast(0%) brightness(200%);
}
.createOrder {
  margin-right: 10px;
  height: 25px;
}

.routeLink:hover {
  color: #01c9d5;
}
.routeNote {
  background: #eeeeee;
  border-radius: 4px;
}

.driverLocationButton {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.carImg {
  margin: 9px 0;
}

.warehouseDeliveryLabel {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 5px;
}

.label {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  height: 20px;
  line-height: 20px;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 0 3px;
  min-width: fit-content;
  color: #eee;
  font-size: 0.75rem;
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .label {
    font-size: 0.75rem;
  }
}

@media (min-width: 1920px) {
  .label {
    font-size: 0.81rem;
  }
}

.sendButtonIcon {
  height: 16px;
  width: 16px;
}

.unsynchronizedCard {
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 10px 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
}

.unsynchronizedCardDetails {
  z-index: 3;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.unsynchronizedCardBody {
  padding: 15px;
  width: 80%;
  max-height: 70%;
  overflow: auto;
  border-radius: 5px;
}

.cabinFont {
  font-family: "Cabin", sans-serif !important;
}
