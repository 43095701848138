.container {
  position: relative;
}
.container input {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 28px;
  opacity: 0;
}
.container:focus-within .checkMark {
  box-shadow: 0px 0px 3px 4px #ececed, 0px 2px 4px 0px rgba(17, 12, 34, 0.12);
}
.container:focus-within .activeMark {
  box-shadow: 0px 0px 0px 4px #e2dcff, 0px 2px 4px 0px rgba(17, 12, 34, 0.12);
}
.row {
  display: flex;
  align-items: center;
}

.checkMark {
  position: relative;
  width: 26px;
  height: 14px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}
.checkMark:before {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.2s;
}

.activeMark:not(.disabled) {
  background: var(--Deep-Purple-5, #8c73ff);
}

.activeMark:before {
  transform: translateX(13px);
  border-color: var(--Deep-Purple-5, #8c73ff);
}
.activeMark:not(.disabled):before {
  box-shadow: 0px 0px 0 4px rgb(87 33 225 / 30%);
}
.container:hover :not(.disabled).activeMark:before {
  box-shadow: 0px 2px 8px -2px rgba(17, 12, 34, 0.12), 0px 0px 0px 4px rgba(151, 129, 255, 0.5);
}
.label {
  font-size: 12px;
  white-space: nowrap;
  flex-wrap: nowrap;
  cursor: pointer;
  font-weight: 700;
}
.textWrapper {
  margin-left: 8px;
}
[disabled] + .label {
  opacity: 0.7;
}

.disabled,
[disabled],
.disabled * {
  cursor: not-allowed !important;
}

.small .checkMark {
  width: 28px;
  height: 16px;
}
.medium .checkMark {
  width: 34px;
  height: 20px;
}
.medium .checkMark:before {
  width: 14px;
  height: 14px;
  bottom: 2px;
}
.medium .activeMark:before {
  transform: translateX(16px);
}
