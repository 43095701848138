.wrapper {
  width: 100%;
}
.rightBar {
  width: 60px;
  background: #0000;
  border: 0;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  padding: 0;
  right: 5px;
  top: 120px;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: auto;
}
.spinnerContainer {
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  position: absolute;
  top: 70px;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.loader div {
  color: #000;
  font-size: 16px;
}
