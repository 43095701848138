.formWrapper {
  width: 100%;
}
.formInner {
  min-width: 540px;
  width: -webkit-fill-available;
}
.sections {
  overflow: auto;
  padding-bottom: 100px;
  min-width: 540px;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 70px);
  min-height: 1px;
  min-width: fit-content;
  min-width: -moz-fit-content;
}
.section {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
  margin: 15px;
  padding: 15px;
}
.section h5 {
  color: #999;
  font-size: 1rem;
  margin-bottom: 15px;
}
.inputShort {
  width: 95px;
  min-width: 95px;
  margin-right: 0.75rem;
}
.inputLong {
  width: -webkit-fill-available;
  width: -moz-available;
}

/* GeneralInfoSection */

.generalInfoSection {
  padding: 10px 0 10px 0;
  margin-bottom: 10px;
}
.title {
  font-size: 36px;
  line-height: 46px;
  padding-top: 10px;
}
.editableGeneralInfoContent {
  padding-top: 10px;
}
.editableElement {
  padding-bottom: 20px;
}

/* ClientAndSellerSection */

.clientAndSellerSection {
  padding-top: 10px;
  max-width: 100%;
}
.clientAndSellerTitle {
  font-size: 20px;
  margin-bottom: 10px;
}
.clientBody {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.clientBodyCollapse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.expandBtn {
  padding: 8px;
  font-size: 10px;
  font-weight: 700;
  color: #8C73FF;
}
.expandBtn:focus {
  background-color: #fff;
}
.editDataBtn {
  padding: 4px 8px;
  font-size: 10px;
}
.editDataBtnContent {
  gap: 6px;
}
.sellerImg {
  margin: 9px 0;
}

/* OrdersSection */

.ordersSection {
  padding-top: 30px;
  margin-top: 10px;
  max-width: 100%;
}

.itemsTable {
  margin-top: 10px;
  padding-top: 10px;
  max-width: 100%;
}

.itemsTableHeader {
  padding-top: 10px;
  padding-bottom: 5px;
}

.itemsRow {
  max-height: 400px;
  overflow: auto;
}

.tradingDocumentItem {
  padding: 10px 0 10px 20px;
}
.externalNumber {
  background-color:  #f1ecec;
  border-radius: 3px;
  padding: 1px 2px 1px 2px;
}

.itemAmount {
  padding-left: 10px;
}

/* PaymentSummarySection */

.paymentSummarySection {
  padding-top: 20px;
  max-width: 100%;
}

.paymentSummaryHeader {
  padding-bottom: 5px;
  padding-left: 20px;
}

.paymentSummaryContent {
  padding: 10px 0 10px 20px;
}

.paymentSummarySum {
  padding: 0 0 10px 20px;
}

.paymentSectionCurrency {
  margin-left: 10px;
}

.paymentSum {
  padding-top: 20px;
}

.paymentSumContent {
  padding: 10px 0;
}

.paymentSumByDigits {
  margin-bottom: 15px;
  font-size: 20px;
}

.summaryBtns {
  padding-top: 30px;
  gap: 10px;
}

.summaryBtn {
  padding: 10px 16px;
  border-radius: 8px;
}
